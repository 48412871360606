const Admin_Panel = 'info@lincolnbookpublishers.com';
const PHONE = '(855) 216-6440';
const PHONE_HREF = 'tel:8552166440';
// const PHONE_TWO = '+61 489 951 988';
// const PHONE_TWO_HREF = 'tel:61489951988';
const EMAIL = 'info@lincolnbookpublishers.com';
const EMAIL_HREF = 'mailto:info@lincolnbookpublishers.com';
const CONTACT_EMAIL = 'info@lincolnbookpublishers.com';
const CONTACT_EMAIL_HREF = 'mailto:info@lincolnbookpublishers.com';
const ADDRESS = '2029 Century Park E, Los Angeles, CA 90067';
const WEBSITE_NAME = 'Lincoln Book Publishers';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  'https://www.lincolnbookpublishers.com/assets/images/logo.png';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  //   PHONE_TWO,
  //   PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
