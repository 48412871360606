import React, { useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Accordion from 'react-bootstrap/Accordion';
import './App.css';
import {
  eclipse1,
  logo,
  eclipse2,
  eclipse3,
  eclipse4,
  logoGif,
  book01,
  book02,
  book03,
  book04,
  book05,
  trustPilotImg,
  secRight,
  visa,
  sec6Right,
  cultureAccIcon,
  directSubIcon,
  agentIntroIcon,
  pubconIcon,
  bookCoachingIcon,
  betaReaderIcon,
  bestSellerIcon,
  publishingNavIcon,
  bookproposalIcon,
  editingIcon,
  ghostwringIcon,
  bookPlanningIcon,
  pubMarketingStraIcon,
  bookeditIcon,
  ghostwriterIcon,
  whiteLogo,
} from './assets/images';
import {
  ADDRESS,
  EMAIL,
  EMAIL_HREF,
  PHONE,
  PHONE_HREF,
  WEBSITE_LOGO,
  WEBSITE_NAME,
} from './config';
import { ModalSection } from './layout/modalSection';

function App() {
  var bookSlider = {
    dots: false,
    prevArrow: false,
    nextArrow: false,
    infinite: true,
    autoplay: true,
    loop: true,
    speed: 200,
    slidesToShow: 5,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "lincolnbookpublishers.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=258181';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thankyou?${queryString}`)
          window.location.href = `https://lincolnbookpublishers.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className="App">
      <div className="header">
        <div className="container">
          <div className="top_menu">
            <div className="row">
              <div className="col-sm-12">
                <div className="combo_btn">
                  <a
                    className="btn_11 btn_chat liveChatButton chat"
                    href="javascript:;"
                  >
                    CHAT NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main_menu">
            <div className="row align-items-center">
              <div className=" col-sm-12 col-lg-3">
                <div className="logo ">
                  <a href="/">
                    <img src={logo} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-sm-12 col-lg-9">
                <div className="menu">
                  <ul>
                    <li>
                      <a href="#our-work">Our Work</a>
                    </li>
                    <li>
                      <a href="#feed-back">Feedback</a>
                    </li>
                    <li>
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <a href="#features">Features</a>
                    </li>
                    <li>
                      <a href="#faqs&amp;contact">Faqs</a>
                    </li>
                    <li className="last">
                      <a href="#faqs&amp;contact">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner">
        <div className="container ">
          <div className="row align-items-center ">
            <div className="col-sm-12 col-lg-4 ">
              <h3>Books that we create,</h3>
              <h1>
                Inspire. <br /> Promote. <br /> Empower.
                <span className="child">
                  Inspire. <br /> Promote. <br /> Empower.
                </span>
              </h1>
              <p>
                We are more than just a
                <b>
                  <i> book writing, editing, and publishing agency, </i>
                </b>
                helping authors to showcase their idea
              </p>
              <div className="banner_btn combo_btn ">
                <a
                  className="btn_11 popup-btn"
                  onClick={onOpenModal}
                  href="javascript:;"
                >
                  Get Quote
                </a>
                <a className="btn_2 liveChatButton chat" href="javascript:;">
                  Schedule a Consult
                </a>
              </div>
              <div className="banner_img ">
                <p>
                  <i>Featured in</i>
                </p>
                <ul>
                  <li>
                    <img src={eclipse1} alt="banner Image... " />
                  </li>
                  <li>
                    <img src={eclipse2} alt="banner Image... " />
                  </li>
                  <li>
                    <img src={eclipse3} alt="banner Image... " />
                  </li>
                  <li>
                    <img src={eclipse4} alt="banner Image... " />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="banner_form">
            <form className="form_submission" onSubmit={onSubmitFunc}>
              <div className="row">
                <div className="col-sm-12 col-lg-3">
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    required=""
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-12 col-lg-3">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required=""
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-sm-12 col-lg-3">
                  <input
                    type="number"
                    name="phone"
                    minlength="10"
                    maxlength="12"
                    placeholder="Phone Number"
                    required=""
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                  {errors && (
                    <div className="error">
                      Phone number must be at least 9 digits long
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-lg-3 last">
                  {loading == true ? (
                    <div className="loader">
                      <img alt="loader" src={logoGif} />
                    </div>
                  ) : (
                    <input
                      className="btn_11"
                      name="submit"
                      type="submit"
                      value="Submit"
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="section1" id="our-work">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec1_top">
                <h2>
                  <span>Our Books</span> - from the vault of Mind-Blowing Reads
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="sec1_content">
                <Slider className="sec1_box" {...bookSlider}>
                  <img src={book01} alt="" />
                  <img src={book02} alt="" />
                  <img src={book03} alt="" />
                  <img src={book04} alt="" />
                  <img src={book05} alt="" />
                  <img src={book01} alt="" />
                  <img src={book02} alt="" />
                  <img src={book03} alt="" />
                  <img src={book04} alt="" />
                  <img src={book05} alt="" />
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section2" id="feed-back">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="sec2_box">
                <p>
                  I had a good experience with Alex. He edited my draft and came
                  over a call to understand the needs and priorities. He shared
                  a few ideas that I liked; then, we moved forward with adding
                  those pointers in the draft. A team is responsive, but they
                  also care about your priorities.
                </p>
                <div className="sec2_btm">
                  <img src={trustPilotImg} alt="Trust Image..." />
                  <h5>
                    Jimmie Adkins
                    <span>Content Analyst</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="sec2_box">
                <p>
                  I was in a back and forth with Michael Anthony. Not only a
                  good editor, but a person too. He reworked on my written
                  drafts, and made it more engaging. He ensured that I may have
                  a final draft of my book before marriage anniversary.
                </p>
                <div className="sec2_btm">
                  <img src={trustPilotImg} alt="Trust Image..." />
                  <h5>
                    Aaron Diane
                    <span>Marketing Specialist</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 fw">
              <div className="sec2_box">
                <p>
                  Finally, a book has been published almost a month ago and I
                  have started to generate some results within a month. I know
                  it’s a long way process, but I’m happy to have some numbers.
                </p>
                <div className="sec2_btm">
                  <img src={trustPilotImg} alt="Trust Image..." />
                  <h5>
                    Donna Gardner
                    <span>Lawyer</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec3_top">
                <h2>How do our book publishing services operate?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <div className="sec3_left">
                <p>
                  We've written proposals and strategies that have led to our
                  clients' ebook publishing prove extremely fruitful. Through
                  our publishing services, we have assisted hundreds of authors.
                  From writing query letters to connecting you with the
                  industry's leading publishers, our industry specialists serve
                  as your committed advisers.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="sec3_right">
                <img src={secRight} alt="Idea Image..." />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section4" id="services">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4">
              <div className="sec4_box">
                <div className="sec4_img">
                  <img src={ghostwriterIcon} alt="" />
                </div>
                <h3>
                  Professional <br /> Ghostwriting
                </h3>
                <p>
                  Work with our team to plan and write a book that expertly
                  captures your style and vision. Our process allows you to work
                  directly with former Big-5 editors and bestselling writers to
                  ensure your book is of the highest caliber.
                </p>
                <a
                  className="btn_11 popup-btn"
                  onClick={onOpenModal}
                  href="javascript:;"
                >
                  Know More
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="sec4_box">
                <div className="sec4_img">
                  <img src={bookeditIcon} alt="" />
                </div>
                <h3>
                  Book <br />
                  Editing
                </h3>
                <p>
                  Work directly with bestselling editors to strengthen the
                  substance and structure of your manuscript. Our editorial
                  services improve the quality of your writing, and industry
                  experts will provide in-depth consultation to advise you
                  exactly how to target the market.
                </p>
                <a
                  className="btn_11 popup-btn"
                  onClick={onOpenModal}
                  href="javascript:;"
                >
                  Know More
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="sec4_box last">
                <div className="sec4_img">
                  <img src={pubconIcon} alt="" />
                </div>
                <h3>
                  Publishing <br />
                  Consulting
                </h3>
                <p>
                  There are thousands of publishing options to consider — let us
                  guide you through the entire process. From developing your
                  ideas into a publish-worthy concept to navigating the entire
                  industry, we’ll ensure your book is in the best hands.
                </p>
                <a
                  className="btn_11 popup-btn"
                  onClick={onOpenModal}
                  href="javascript:;"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section5">
        <div className="container">
          <div className="row">
            <div className="sec5_top">
              <h2>
                <span>Win the Title of Best-Seller</span> - with our Expert
                Competencies
              </h2>
            </div>
          </div>
        </div>
        <div className="sec5_content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box">
                  <div className="sec5_img">
                    <img src={bookPlanningIcon} alt="" />
                  </div>
                  <h3>Book-Planning</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box bg">
                  <div className="sec5_img">
                    <img src={ghostwringIcon} alt="" />
                  </div>
                  <h3>Ghostwriting</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box">
                  <div className="sec5_img">
                    <img src={editingIcon} alt="" />
                  </div>
                  <h3>Editing</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box bg">
                  <div className="sec5_img">
                    <img src={bookproposalIcon} alt="" />
                  </div>
                  <h3>Book-Proposals &amp; Queries</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box">
                  <div className="sec5_img">
                    <img src={publishingNavIcon} alt="" />
                  </div>
                  <h3>Publishing Navigation</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box bg">
                  <div className="sec5_img">
                    <img src={bestSellerIcon} alt="" />
                  </div>
                  <h3>Bestseller Targeting</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box bg">
                  <div className="sec5_img">
                    <img src={betaReaderIcon} alt="" />
                  </div>
                  <h3>Beta Reader Testing</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box ">
                  <div className="sec5_img">
                    <img src={bookCoachingIcon} alt="" />
                  </div>
                  <h3>Book Coaching</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box bg">
                  <div className="sec5_img">
                    <img src={pubMarketingStraIcon} alt="" />
                  </div>
                  <h3>Publishing &amp; Marketing Strategy</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box ">
                  <div className="sec5_img">
                    <img src={agentIntroIcon} alt="" />
                  </div>
                  <h3>Agent Introductions</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box bg">
                  <div className="sec5_img">
                    <img src={directSubIcon} alt="" />
                  </div>
                  <h3>Direct Submissions to Publishers</h3>
                </div>
              </div>
              <div className="col-sm-12 col-lg-2">
                <div className="sec5_box ">
                  <div className="sec5_img">
                    <img src={cultureAccIcon} alt="" />
                  </div>
                  <h3>Culture Accuracy Reading</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section6">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-lg-5">
              <div className="sec6_left">
                <h2>
                  Stop Thinking &amp; <br /> Start Composing
                </h2>
                <h3>Your Master Piece!</h3>
                <p>Let us help you create a Remarkable Book by your Name.</p>
                <div className="combo_btn">
                  <a
                    className="btn_11 popup-btn"
                    onClick={onOpenModal}
                    href="javascript:;"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-7">
              <div className="sec6_right">
                <img src={sec6Right} alt="Section Six Images..." />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section8" id="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sec8_top">
                <h2>
                  Reasons for choosing <br /> Lincoln Book Publishers
                </h2>
              </div>
            </div>
          </div>
          <div className="sec8_content">
            <div className="sec8_box">
              <div className="sec8_item">
                <h3 className="after1">Professional Bookwriters</h3>
                <p>
                  Our team includes talented editors and writers who have
                  achieved bestselling results across multiple genres
                </p>
              </div>
              <div className="sec8_item">
                <h3 className="after2">Experienced Editors</h3>
                <p>
                  Our Senior and Executive Editors are former acquisitions
                  editors from Big-5 publishing houses.
                </p>
              </div>
              <div className="sec8_item">
                <h3 className="after3">Publishing Navigation</h3>
                <p>
                  We work directly with the industry’s top publishers, hybrid
                  presses, and marketing professionals — we will put together
                  the optimum publishing strategy and be your trusted guide.
                </p>
              </div>
            </div>
            <div className="sec8_box">
              <div className="sec8_item">
                <h3 className="after4">Timely deliverables</h3>
                <p>
                  Our comprehensive service allows you to be as involved or
                  hands-off as you wish.
                </p>
              </div>
              <div className="sec8_item">
                <h3 className="after5">Personalized Approach</h3>
                <p>
                  Every idea, every author, and every book is unique. We pair
                  you with a team that knows your target audience, understands
                  your subject matter, and can capture your individual voice.
                </p>
              </div>
              <div className="sec8_item">
                <h3 className="after6">Proven Process</h3>
                <p>
                  Work with a team that has refined the art of successful
                  writing and editing, leading to the publication of over 1,500
                  books and over 250 national bestsellers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="section9">
          <div className="row">
            <div className="col-lg-12">
              <h2>Is your Idea</h2>
              <h3>The Next Bestseller?</h3>
              <div className="combo_btn">
                <a
                  className="btn_11 popup-btn"
                  onClick={onOpenModal}
                  href="javascript:;"
                >
                  LET'S PITCH IT TOGETHER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section10" id="faqs&amp;contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="sec10_box">
                <h2>
                  Got Questions?
                  <br /> These Might Help!
                </h2>
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0" className="card">
                    <Accordion.Header className="card-header">
                      Will the book be published with my name?
                    </Accordion.Header>

                    <Accordion.Body>
                      With our ghostwriting services, turn your ideas into a
                      book and get it published under your name.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="card">
                    <Accordion.Header className="card-header">
                      Why should I choose Lincoln Book Publishers?
                    </Accordion.Header>
                    <Accordion.Body>
                      We not only write super-engaging books, but we publish
                      them on the industry’s leading platforms, such as Amazon.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="card">
                    <Accordion.Header className="card-header">
                      Which platforms will my book be published on?
                    </Accordion.Header>
                    <Accordion.Body>
                      Your book will be formatted according to and published on
                      leading platforms such as Amazon and Barnes and Noble.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="card">
                    <Accordion.Header className="card-header">
                      What is the difference between publishing platforms?
                    </Accordion.Header>
                    <Accordion.Body>
                      Every platform has strict formatting requirements and only
                      accepts books that comply with the set criterion. We at
                      Lincoln Book Publishers will format and edit your book
                      accordingly.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="sec10_box">
                <h2>
                  Let’s write a
                  <br /> Book Together!
                </h2>
                <div className="sec10_form">
                  <form className="form_submission" onSubmit={onSubmitFunc}>
                    <div className="row">
                      <div className="col-lg-12">
                        <input
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-lg-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-lg-12">
                        <input
                          type="number"
                          name="phone"
                          minlength="10"
                          maxlength="12"
                          placeholder="Phone Number"
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                        />
                        {errors && (
                          <div className="error">
                            Phone number must be at least 9 digits long
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          name="message"
                          placeholder="Your Idea..."
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <div className="col-lg-12">
                        {loading == true ? (
                          <div className="loader btn_11">
                            <img alt="loader" src={logoGif} />
                          </div>
                        ) : (
                          <input
                            className="btn_11"
                            name="submit"
                            type="submit"
                            value="SCHEDULE A CONSULT"
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-lg-4">
              <img
                className="ft_logo"
                src={whiteLogo}
                alt="Footer Logo Image..."
              />
            </div>
            <div className="col-sm-12 col-lg-8">
              <p>
                At Lincoln Book Publishers, we undertake the onus of
                orchestrating your project in a meticulous manner – one which
                doesn’t only ensure that we get best in the market.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="ft_links">
                <li>
                  <div class="main-pages">
                    <ul>
                      <li className="facebook">
                        <a
                          href="https://www.facebook.com/lincolnbookpublishers"
                          target="_blank"
                        >
                          <i class="fa-brands fa-facebook"></i>
                        </a>
                      </li>

                      <li className="instagram">
                        <a
                          href="https://www.instagram.com/lincolnbookpublishers/"
                          target="_blank"
                        >
                          <i class="fa-brands fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.linkedin.com/company/lincoln-book-publishers/"
                          target="_blank"
                        >
                          <i class="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="javascript:;">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    {ADDRESS}
                  </a>
                </li>
                <li>
                  <a href={EMAIL_HREF}>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <span className="__cf_email__">{EMAIL}</span>
                  </a>
                </li>
                <li>
                  <a href={PHONE_HREF}>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    {PHONE}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <img
                className="payment"
                src={visa}
                alt="Payment Method Images..."
              />
              <p>© 2024 {WEBSITE_NAME} | All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      <ModalSection open={open} onCloseModal={onCloseModal} />
    </div>
  );
}

export default App;
